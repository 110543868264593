export default [
    {
      title: 'breadcrumb.configuration',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'breadcrumb.company',
          route: 'list-company',
          icon: 'SettingsIcon',
          resource: 'company',
          action: 'read'
        },
        {
          title: 'breadcrumb.department',
          route: 'list-department',
          icon: 'SettingsIcon',
          resource: 'department',
          action: 'read'
        },
        {
          title: 'breadcrumb.position',
          route: 'list-position',
          icon: 'SettingsIcon',
          resource: 'position',
          action: 'read'
        },
        {
          title: 'breadcrumb.supplier',
          route: 'list-supplier',
          icon: 'SettingsIcon',
          resource: 'supplier',
          action: 'read'
        },
        {
          title: 'breadcrumb.unit',
          route: 'list-unit',
          icon: 'SettingsIcon',
          resource: 'unit',
          action: 'read'
        },
        {
          title: 'breadcrumb.requestPurpose',
          route: 'list-request-purpose',
          icon: 'SettingsIcon',
          resource: 'request-purpose',
          action: 'read'
        },
        {
          title: 'breadcrumb.requestSection',
          route: 'list-request-section',
          icon: 'SettingsIcon',
          resource: 'request-section',
          action: 'read'
        },
      ],
    },
  ];