export default [
    {
      title: 'breadcrumb.report',
      icon: 'BookIcon',
      children: [
        {
          title: 'breadcrumb.inventoryReport',
          route: 'list-inventory-report',
          icon: 'PackageIcon',
          resource: 'inventory-report',
          action: 'read'
        },
        {
          title: 'breadcrumb.stockReport',
          route: 'list-stock-report',
          icon: 'PackageIcon',
          resource: 'stock-report',
          action: 'read'
        },
      ],
    }
  ];