export default [
    {
      title: 'breadcrumb.stock',
      icon: 'PackageIcon',
      children: [
        {
          title: 'breadcrumb.stockLocation',
          route: 'list-stock-location',
          icon: 'MapPinIcon',
          resource: 'stock-location',
          action: 'read'
        },
        {
          title: 'breadcrumb.bin',
          route: 'list-stock-bin',
          icon: 'InboxIcon',
          resource: 'stock-bin',
          action: 'read'
        },
        {
          title: 'breadcrumb.stock',
          route: 'list-stock',
          icon: 'PackageIcon',
          resource: 'stock',
          action: 'read'
        },
        {
          title: 'breadcrumb.stockRequest',
          route: 'list-stock-request',
          icon: 'FileTextIcon',
          resource: 'stock-request',
          action: 'read'
        },
        {
          title: 'breadcrumb.purchaseRequestReport',
          route: 'list-purchase-request-report',
          icon: 'PackageIcon',
          resource: 'purchase-request-report',
          action: 'read'
        },
      ],
    }
  ];