export default [
  {
    title: 'breadcrumb.user',
    icon: 'UsersIcon',
    children: [
      {
        title: 'breadcrumb.administrator',
        route: 'list-administrator',
        icon: 'UserIcon',
        resource: 'administrator',
        action: 'read'
      },
      {
        title: 'breadcrumb.rolePermission',
        route: 'list-role-permission',
        icon: 'UnlockIcon',
        resource: 'role-permission',
        action: 'read'
      },
      {
        title: 'breadcrumb.employee',
        route: 'list-employee',
        icon: 'UserIcon',
        resource: 'employee',
        action: 'read'
      },
      {
        title: 'breadcrumb.stockAdmin',
        route: 'list-stock-admin',
        icon: 'UserIcon',
        resource: 'stock-admin',
        action: 'read'
      },
    ],
  },
];