import dashboard from "./menu/dashboard";
import user from "./menu/user";
import configuration from "./menu/configuration";
import item from "./menu/item";
import stock from "./menu/stock";
import report from "./menu/report";

export default [
  ...dashboard,
  ...user,
  ...item,
  ...stock,
  ...report,
  ...configuration
]
